
:root {
  // Colors
  --primary: #009B7D;
  --secondary: #FFBF5D;
  --bluebg:#0e1e2a;

  --white: #fff;
  --white-2: #e3e3e3;

  --black: #000;
  --black-2: #252525;
  --black-3: #1f242c;
  --black-4: #67687a;
  --black-5: #2b2b5e;

  --gray: #616161;
  --gray-2: #f4f5fa;
  --gray-3: #333f4d;
  --gray-4: #82828a;
  --gray-5: #3c4958;
  --gray-6: #757474;

  --blue: #009B7D;
  --blue-2: #064bb5;

  --yellow: #b8fb5e;
  --yellow-2: #d8ff36;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  overflow-x: hidden;
}

// Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Font Family
$font_barlow: "Barlow", sans-serif;

// Responsive Variable
$xxxl: "(min-width: 1920px)";
$xxl: "(max-width: 1919px)";
$xl: "(max-width: 1399px)";
$lg: "(max-width: 1199px)";
$md: "(max-width: 991px)";
$sm: "(max-width: 767px)";
$xs: "(max-width: 574px)";
